import React, { useCallback } from "react";
import { graphql } from "gatsby";
import PropTypes from "prop-types";
import RscHbCardArticle from "~components/molecules/rsc_hb_card/rsc_hb_card_article/rsc_hb_card_article";
import RscHbCardArticleFeatured from "~components/molecules/rsc_hb_card/rsc_hb_card_article_featured/rsc_hb_card_article_featured";
import RscHbCtaLink from "~components/molecules/rsc_hb_cta/rsc_hb_cta_link/rsc_hb_cta_link";
import RscHbInPageContactForm from "~components/molecules/rsc_hb_in_page_contact_form/rsc_hb_in_page_contact_form";
import RscHbPageHeader from "~components/molecules/rsc_hb_page_header/rsc_hb_page_header";
import RscHbPageWrapper from "~components/molecules/rsc_hb_page_wrapper/rsc_hb_page_wrapper";
import RscHbPaginatedList from "~components/molecules/rsc_hb_paginated_list/rsc_hb_paginated_list";
import usePageData from "~hooks/use_page_data/use_page_data";
import {
  ASSET,
  RESOURCE_HUB_SECTION,
  STORYBLOK_ENTRY,
  STORYBLOK_ENTRY_ARRAY,
} from "../../prop_types";
import TemplateGlobalEntry from "../template_global_entry/template_global_entry";
import RscHbSectionInner from "../template_resource_hub/components/rsc_hb_section_inner/rsc_hb_section_inner";
import { GRID_SPLIT_ARTICLES } from "../template_resource_hub/grid_splits";

export default function TemplateListCaseStudies(props) {
  const { story, pageContext, entryAssets } = usePageData(props);

  const {
    breadcrumbsItems,
    contactForm,
    currentPage,
    itemsPerPage,
    lang,
    pageCount,
    sections,
    pagePath,
    subNavData,
    UIStrings,
    navigationConfig,
    resourceHubCtaConfig,
  } = pageContext;

  const { paginationSectionObject, featuredItem } = sections;

  const { title, subtitle, featuredItemTitle, allItemsTitle } = story;

  const isAtTopLevel = currentPage === 1;

  const { ctaSignUp } = resourceHubCtaConfig || {};

  const CtaComponent = useCallback(() => {
    return <RscHbCtaLink {...ctaSignUp} />;
  }, [ctaSignUp]);

  return (
    <TemplateGlobalEntry
      subNavData={subNavData}
      entryAssets={entryAssets}
      {...props}
      headerColor="blue"
      pagePath={pagePath}
      programmaticPageTitle={title}
      isListPage
    >
      <RscHbPageWrapper
        items={subNavData}
        lang={lang}
        level1ActiveItem="customers"
        navigationConfig={navigationConfig}
      >
        <RscHbPageHeader
          breadcrumbsItems={breadcrumbsItems}
          title={title}
          description={subtitle}
        />

        {/* ————— FEATURED CASE STUDY ————— */}
        {featuredItem && isAtTopLevel && (
          <RscHbSectionInner title={featuredItemTitle}>
            <RscHbCardArticleFeatured {...featuredItem.items[0]} />
          </RscHbSectionInner>
        )}

        {/* ————— ALL CASE STUDIES ————— */}
        <RscHbPaginatedList
          allItemsTitle={allItemsTitle}
          basePath={pagePath}
          contentCardComponent={RscHbCardArticle}
          ctaComponent={CtaComponent}
          currentPage={currentPage}
          gridSplit={GRID_SPLIT_ARTICLES}
          items={paginationSectionObject.items}
          itemsPerPage={itemsPerPage}
          pageCount={pageCount}
          UIStrings={UIStrings}
        />
      </RscHbPageWrapper>
      <RscHbInPageContactForm lang={lang} {...contactForm} />
    </TemplateGlobalEntry>
  );
}

TemplateListCaseStudies.propTypes = {
  data: PropTypes.shape({
    story: STORYBLOK_ENTRY,
    items: STORYBLOK_ENTRY_ARRAY,
    assets: PropTypes.shape({
      nodes: PropTypes.arrayOf(ASSET),
    }),
  }).isRequired,
  pageContext: PropTypes.shape({
    breadcrumbsItems: PropTypes.arrayOf(PropTypes.shape({})),
    contactForm: PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
    }),
    currentPage: PropTypes.number,
    itemsPerPage: PropTypes.number,
    lang: PropTypes.string,
    pageCount: PropTypes.number,
    pagePath: PropTypes.string,
    UIStrings: PropTypes.shape({}),
    sections: PropTypes.shape({
      featuredItem: RESOURCE_HUB_SECTION,
      paginationSectionObject: RESOURCE_HUB_SECTION,
    }),
    subNavData: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
};

export const query = graphql`
  query listPageCaseStudiesQuery(
    $id: String
    $langRegex: String
    $allCoverIDs: [String]
  ) {
    ...resourceHubStory
    ...global
    ...resourceHubCoverAssets
  }
`;
